<template>
    <div></div>
</template>

<style>
    div {
        width: 100%;
        height: 200px;
        background: red;
        animation: pulse 5s infinite;
    }

    @keyframes pulse {
        0% {
            background-color: #001F3F;
        }
        100% {
            background-color: #FF4136;
        }
}
</style>